<template>
    <div class="addWrite">
        <div class="addWrite-box">
            <el-form
                ref="form"
                style="
                    flex: 1;
                    margin-top: 20px;
                    margin-left: 40px;
                    margin-bottom: 80px;
                "
                label-position="left"
                :model="form"
                label-width="100px"
            >
                <el-form-item label="付款方" required style="">
                    <el-input
                        v-model.trim="form.contractA"
                        size="small"
                        placeholder="请输入付款方"
                        clearable=""
                        v-bind:disabled='form.bankType==1 || form.bankType==2  || form.bankType==3 || form.bankType==4 || form.bankType==5'
                    ></el-input>
                </el-form-item>
                <el-form-item label="批注">
                    <el-input
                        v-model.trim="form.annotate"
                        size="small"
                        placeholder="请输入批注"
                        clearable=""
                    ></el-input>
                </el-form-item>
                <el-form-item label="收款方" required style="">
                    <el-select
                        v-model="form.typeB"
                        size="small"
                        style="width: 270px"
                        placeholder="请选择收款方"
                        clearable
                        v-bind:disabled='form.bankType==1 || form.bankType==2  || form.bankType==3 || form.bankType==4 || form.bankType==5'
                    >
                        <el-option
                            v-for="item in $tableDataHandle.selectTypeB()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="form.typeB == 9">
                    <el-input
                        v-model="form.contractB"
                        size="small"
                        placeholder="请输入"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="收款方式" required style="">
                    <el-select
                        v-model="form.entryMethod"
                        size="small"
                        style="width: 270px"
                        placeholder="请选择收款方式"
                        clearable
                    >
                        <el-option label="对公" :value="1"></el-option>
                        <el-option label="微信" :value="2"></el-option>
                        <el-option label="支付宝" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="金额" required style="">
                    <el-input
                        v-model="form.arriveAmount"
                        size="small"
                        placeholder="请输入金额"
                        clearable=""
                        v-bind:disabled='form.bankType==1 || form.bankType==2  || form.bankType==3 || form.bankType==4 || form.bankType==5'
                    ></el-input>
                </el-form-item>
                <el-form-item label="到账时间" required style="">
                    <el-date-picker
                        v-model="form.arriveTime"
                        style="width: 270px"
                        size="small"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        v-bind:disabled='form.bankType==1 || form.bankType==2  || form.bankType==3 || form.bankType==4 || form.bankType==5'
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="类别" required>
                    <el-select
                        size="small"
                        style="width: 270px"
                        clearable
                        v-model="form.category"
                        filterable
                        placeholder="请选择或输入关键字搜索"
                    >
                        <el-option label="直销" :value="1"></el-option>
                        <el-option label="渠道" :value="2"></el-option>
                        <el-option label="返点" :value="3"></el-option>
                        <el-option label="其他" :value="4"></el-option>
                        <el-option label="退款" :value="5"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="可见范围">
                    <el-cascader
                        :key="modalKey"
                        :options="options"
                        :props="{
                            value: 'id',
                            label: 'nickName',
                            children: 'children',
                            multiple: true,
                            expandTrigger: 'hover',
                        }"
                        style="width: 270px"
                        size="small"
                        collapse-tags
                        v-model="form.options"
                        clearable
                    ></el-cascader>
                </el-form-item>
                <!-- <el-form-item label="员工">
                    <el-select
                        size="small"
                        style="width: 270px"
                        clearable
                        v-model="form.staffAdminId"
                        filterable
                        placeholder="请选择或输入关键字搜索"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="备注">
                    <el-input
                        v-model="form.remark"
                        size="small"
                        placeholder="请输入"
                        clearable
                        :disabled="true"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 32px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { arriveUpdate, arriveGet } from '@/api/user/finance/write';
import { AddAssistList } from '@/api/user/ae/customer';
import { roleList } from '@/api/customer/customer.js';
import { userList, departmentList } from '@/api/admin/framework/framework.js';
export default {
    data() {
        return {
            form: {
                id: '',
                contractA: '',
                typeB: '',
                contractB: '',
                arriveTime: '',
                arriveAmount: '',
                staffAdminId: '',
                remark: '',
                entryMethod: 1,
                options: [],
                category: '',
                annotate: '',
            },
            options: [],
            data: {},
            originalOptions: [],
            modalKey: 0,
        };
    },
    methods: {
        getData(data) {
            this.form.options = [];

            console.log(data);
            let datas = {
                param: {
                    id: data.id,
                },
            };
            arriveGet(datas).then((res) => {
                this.data = res.data;
                this.form.id = res.data.id;
                this.form.contractA = res.data.contractA;
                this.form.typeB = res.data.typeB;
                this.form.arriveTime = res.data.arriveTime;
                this.form.arriveAmount = res.data.arriveAmount;
                this.form.staffAdminId = res.data.staffAdminId;
                this.form.remark = res.data.remark;
                this.form.category = res.data.category;
                this.form.entryMethod = res.data.entryMethod;
                this.form.bankType = res.data.bankType;
                if (res.data.typeB == 9) {
                    this.form.contractB = res.data.contractB;
                }
                this.form.annotate = res.data.annotate;

                this.roleList();
            });
        },
        roleList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                res.data.forEach((i) => {
                    i.nickName = i.departmentName;
                    let obj = {
                        param: {
                            id: i.id,
                        },
                        pageNum: 0,
                        pageSize: 0,
                    };
                    userList(obj).then((r) => {
                        i.children = r.data.list;
                    });
                });
                setTimeout(() => {
                    this.originalOptions = res.data;
                    this.options = this.$Tree.treeDataTranslate(res.data);
                    this.data.arriveAdminBOList.forEach((item) => {
                        if (
                            this.returnArr(item.departmentId) != 1 &&
                            this.returnArr(item.departmentId)
                        ) {
                            this.form.options.push([
                                this.returnArr(item.departmentId),
                                item.departmentId,
                                item.adminId,
                            ]);
                        } else {
                            this.form.options.push([
                                item.departmentId,
                                item.adminId,
                            ]);
                        }
                    });
                }, 1000);
            });
        },
        returnArr(departmentId) {
            var num = '';
            this.originalOptions.forEach((item) => {
                if (item.id == departmentId) {
                    num = item.parentId;
                }
            });
            return num;
        },
        onSubmit() {
            if (!this.form.contractA) {
                this.$message.error('请输入付款方');
            } else if (!this.form.typeB) {
                this.$message.error('请选择收款方');
            } else if (this.form.typeB == 9 && !this.form.contractB) {
                this.$message.error('请输入收款方');
            } else if (!this.form.arriveTime) {
                this.$message.error('请选择到账时间');
            } else if (!this.form.arriveAmount) {
                this.$message.error('请输入金额');
            } else if (!this.form.category) {
                this.$message.error('请选择类别');
            } else {
                let data = {
                    param: this.form,
                };
                let arriveAdminDOList = [];
                this.form.options.forEach((item) => {
                    arriveAdminDOList.push({
                        adminId: item[item.length - 1],
                    });
                });
                data.param.arriveAdminDOList = arriveAdminDOList;
                arriveUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('修改成功');
                        this.onClose();
                    }
                });
            }
        },
        onClose() {
            this.$emit('close');
            for (let i in this.form) {
                this.form[i] = '';
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.addWrite {
    width: 100%;
    .el-input {
        width: 270px;
    }
    .addWrite-box {
        padding-left: 42px;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        margin-top: 16px;
        text-align: right;

        button {
            width: 90px;
        }
    }
}
</style>
