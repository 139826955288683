<template>
    <div class="addWrite">
        <div class="addWrite-box">
            <el-form
                ref="form"
                style="
                    flex: 1;
                    margin-top: 20px;
                    margin-left: 40px;
                    margin-bottom: 80px;
                "
                label-position="left"
                :model="form"
                label-width="100px"
            >
                <el-form-item label="付款方" required>
                    <el-input
                        style=""
                        v-model.trim="form.contractA"
                        size="small"
                        placeholder="请输入付款方"
                        clearable=""
                    ></el-input>
                </el-form-item>
                <el-form-item label="收款方" required>
                    <el-select
                        v-model="form.typeB"
                        size="small"
                        style="width: 270px"
                        placeholder="请选择收款方"
                        clearable
                    >
                        <el-option
                            v-for="item in $tableDataHandle.selectTypeB()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="form.typeB == 9">
                    <el-input
                        v-model="form.contractB"
                        size="small"
                        placeholder="请输入"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="付款方式" required>
                    <el-select
                        v-model="form.entryMethod"
                        size="small"
                        style="width: 270px"
                        placeholder="请选择付款方式"
                        clearable
                    >
                        <el-option label="对公" :value="1"></el-option>
                        <el-option label="微信" :value="2"></el-option>
                        <el-option label="支付宝" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="金额" required>
                    <el-input
                        style=""
                        v-model="form.arriveAmount"
                        size="small"
                        placeholder="请输入金额"
                        clearable=""
                    ></el-input>
                </el-form-item>
                <el-form-item label="到账时间" required>
                    <el-date-picker
                        v-model="form.arriveTime"
                        style="width: 270px"
                        size="small"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="类别" required>
                    <el-select
                        size="small"
                        style="width: 270px"
                        clearable
                        v-model="form.category"
                        filterable
                        placeholder="请选择或输入关键字搜索"
                    >
                        <el-option label="直销" :value="1"></el-option>
                        <el-option label="渠道" :value="2"></el-option>
                        <el-option label="返点" :value="3"></el-option>
                        <el-option label="其他" :value="4"></el-option>
                        <el-option label="退款" :value="5"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="可见范围" required>
                    <el-cascader
                        :options="options"
                        :props="{
                            value: 'id',
                            label: 'nickName',
                            children: 'children',
                            multiple: true,
                            expandTrigger: 'hover',
                        }"
                        style="width: 270px"
                        size="small"
                        v-model="form.options"
                        @change="handleChange"
                        collapse-tags
                        clearable
                    >
                    </el-cascader>
                </el-form-item>
                <!-- <el-form-item label="员工">
                    <el-select
                        size="small"
                        style="width: 270px"
                        clearable
                        v-model="form.staffAdminId"
                        filterable
                        placeholder="请选择或输入关键字搜索"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="备注">
                    <el-input
                        v-model="form.remark"
                        size="small"
                        placeholder="请输入"
                        clearable
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 32px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { arriveAdd } from '@/api/user/finance/write';
import { AddAssistList } from '@/api/user/ae/customer';
import { roleList } from '@/api/customer/customer.js';
import { userList, departmentList } from '@/api/admin/framework/framework.js';
export default {
    data() {
        return {
            form: {
                contractA: '',
                typeB: '',
                contractB: '',
                arriveTime: this.$handTime.getFormatDate(),
                arriveAmount: '',
                staffAdminId: '',
                remark: '',
                entryMethod: 1,
                options: [],
                category: '',
            },
            options: [],
        };
    },
    mounted() {
        this.roleList();
        // this.getUserList();
    },
    methods: {
        getUserList() {
            let data = {
                param: {
                    roleTypeList: [1, 2, 3, 4, 5, 6, 8, 9, 10],
                },
            };
            AddAssistList(data).then((res) => {
                this.options = res.data;
            });
        },
        cascaderClick(nodeData) {
            console.log(nodeData);
            // this.departmentId = nodeData.id;

            // this.$refs.cascader.checkedValue = nodeData.departmentName;
            // this.$refs.cascader.computePresentText();
            // this.$refs.cascader.toggleDropDownVisible(false);
        },
        roleList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                res.data.forEach((i) => {
                    i.nickName = i.departmentName;
                    let obj = {
                        param: {
                            id: i.id,
                        },
                        pageNum: 0,
                        pageSize: 0,
                    };
                    userList(obj).then((r) => {
                        i.children = r.data.list;
                    });
                });
                setTimeout(() => {
                    this.options = this.$Tree.treeDataTranslate(res.data);
                }, 1000);
            });
            // roleList(data).then((res) => {
            //     if (res.code == 200) {
            //         console.log(res.data);
            //         res.data.forEach((item) => {
            //             if (item.roleType == 15) {
            //                 this.form.options.push([
            //                     item.departmentId,
            //                     item.id,
            //                 ]);
            //             }
            //         });
            //         this.options = this.$Tree.treeDataTranslate(res.data);
            //         // this.options = this.sortClass(res.data);
            //         // this.options = this.options.map((item, index) => {
            //         //     let arr = {};
            //         //     arr.children = [];
            //         //     item.forEach((itm) => {
            //         //         arr.value = itm.departmentId;
            //         //         arr.label = itm.departmentName;
            //         //         arr.children.push({
            //         //             value: itm.id,
            //         //             label: itm.nickName,
            //         //         });
            //         //     });
            //         //     return arr;
            //         // });
            //         // console.log(this.options);
            //     }
            // });
        },
        sortClass(sortData) {
            const groupBy = (array, f) => {
                let groups = {};
                array.forEach((o) => {
                    if (o.departmentId && o.departmentName) {
                        let group = JSON.stringify(f(o));
                        groups[group] = groups[group] || [];
                        groups[group].push(o);
                    }
                });
                return Object.keys(groups).map((group) => {
                    return groups[group];
                });
            };
            const sorted = groupBy(sortData, (item) => {
                return {
                    value: item.departmentId,
                    label: item.departmentName,
                }; // 返回需要分组的对象
            });
            return sorted;
        },
        handleChange(val) {
            // console.log(this.form.options);
        },
        onClose() {
            this.$emit('close');
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.form.entryMethod = 1;
            this.form.todoTime = this.$handTime.getFormatDate();
            this.form.arriveTime = this.$handTime.getFormatDate();
        },

        onSubmit() {
            if (!this.form.contractA) {
                this.$message.error('请输入付款方');
            } else if (!this.form.typeB) {
                this.$message.error('请选择收款方');
            } else if (this.form.typeB == 9 && !this.form.contractB) {
                this.$message.error('请输入收款方');
            } else if (!this.form.arriveTime) {
                this.$message.error('请选择到账时间');
            } else if (!this.form.arriveAmount) {
                this.$message.error('请输入金额');
            } else if (this.form.options.length == 0) {
                this.$message.error('请选择可见范围');
            } else if (!this.form.category) {
                this.$message.error('请选择类别');
            } else {
                let data = {
                    param: this.form,
                };
                let arriveAdminDOList = [];
                this.form.options.forEach((item) => {
                    arriveAdminDOList.push({
                        adminId: item[1],
                    });
                });
                data.param.arriveAdminDOList = arriveAdminDOList;

                arriveAdd(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('添加成功');
                        this.onClose();
                    }
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
/deep/.el-checkbox__input {
    width: 100% !important;
    height: 100% !important;
    z-index: 10 !important;
    position: absolute !important;
}
/deep/.el-cascader-node__label {
    margin-left: 10px;
}
/* 这个样式针对IE有用，不考虑IE的可以不用管*/
/deep/.el-cascader-panel .el-cascader-node__postfix {
    top: 10px;
}
.addWrite {
    width: 100%;
    .el-input {
        width: 270px;
    }
    .addWrite-box {
        padding-left: 42px;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        margin-top: 16px;
        text-align: right;

        button {
            width: 90px;
        }
    }
}
</style>
