<template>
    <div class="addWrite">
        <div class="addWrite-box">
            <el-form
                style="
                    flex: 1;
                    margin-top: 20px;
                    margin-left: 40px;
                    margin-bottom: 80px;
                "
                label-position="left"
                label-width="100px"
            >
                <el-form-item label="付款方">
                    <span class="text">{{ data.contractA }}</span>
                </el-form-item>
                <el-form-item label="收款方">
                    <span class="text">{{
                        $tableDataHandle.typeB(data.typeB)
                    }}</span>
                </el-form-item>
                <el-form-item v-if="data.typeB == 9">
                    <span class="text">{{ data.contractB }}</span>
                </el-form-item>
                <el-form-item label="收款方式">
                    <span class="text">{{
                        data.entryMethod == 1
                            ? '对公'
                            : data.entryMethod == 2
                            ? '微信'
                            : data.entryMethod == 3
                            ? '支付宝'
                            : '其他'
                    }}</span>
                </el-form-item>
                <el-form-item label="金额">
                    <span class="text">{{ data.arriveAmount }}</span>
                </el-form-item>
                <el-form-item label="到账时间">
                    <span class="text">{{ data.arriveTime }}</span>
                </el-form-item>
                <el-form-item label="类别">
                    <span class="text">{{
                        data.category == 1
                            ? '直销'
                            : data.category == 2
                            ? '渠道'
                            : data.category == 3
                            ? '返点'
                            : '- -'
                    }}</span>
                </el-form-item>

                <el-form-item label="备注">
                    <span class="text">{{ data.remark }}</span>
                </el-form-item>
                <p class="text">作废后的到款信息将不会与业绩申报匹配，请确认</p>
            </el-form>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 32px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { arriveUpdate } from '@/api/user/finance/write';
export default {
    data() {
        return {
            data: {},
        };
    },
    methods: {
        getData(data) {
            this.data = data;
        },
        onSubmit() {
            let data = {
                param: {
                    id: this.data.id,
                    status: 3,
                },
            };
            arriveUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.addWrite {
    width: 100%;
    .el-input {
        width: 270px;
    }
    .addWrite-box {
        padding-left: 42px;
    }
    .text {
        color: #333333;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        margin-top: 16px;
        text-align: right;

        button {
            width: 90px;
        }
    }
}
</style>
